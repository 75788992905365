import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';

import {Observable} from 'rxjs';
import Swal from 'sweetalert2';

import {emptyUser, User} from '../models/auth';

import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class AuthService {
  public userData: User;
  private httpOptions: any;

  constructor(
    protected http: HttpClient,
    private router: Router) {
  }

  /**
   * login(): metódo chamado ao submeter o formulário de login
   */
  public login(user: string, pass: string): Observable<User> {
    return new Observable<User>((subscriber) => {
      this.httpOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
          }
        )
      };

      this.http.post(
        environment.host + ':' + environment.loginPort + '/authenticate',
        {
          login: user,
          senha: pass,
        },
        this.httpOptions).subscribe({
        next: (success: any) => {
          const resp = JSON.stringify(success);

          if (resp === '{}') {
            Swal.fire({
              title: 'Erro!',
              text: 'Login ou Senha inválido.',
              icon: 'error',
              confirmButtonText: 'Ok',
              width: 400
            });
          }

          if (success && resp !== '{}') {
            this.userData = success;
            localStorage.setItem('User', JSON.stringify(success));

            if (!!success.token) {
              localStorage.rpToken = success.token;
              subscriber.next(this.userData);
              subscriber.complete();
            }
          }
        },
        error: (error) => {
          subscriber.error(error);
        },
        complete: () => {
          subscriber.complete();
        }
      });
    });
  }

  /**
   * getUserData(): salva a sessão do usuário
   */
  public getUserData(): User {
    const aux = localStorage.getItem('User');
    this.userData = aux ? JSON.parse(aux) : emptyUser();

    return this.userData;
  }

  /**
   * logOut(): metódo chamado ao deslogar o usuário
   */
  public logOut(): User {
    this.userData = emptyUser();
    localStorage.removeItem('User');
    localStorage.removeItem('rpToken');
    this.router.navigate(['/']);

    return this.userData;
  }
}


