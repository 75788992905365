<mat-toolbar>
  <div class="rp-cont-mat-toolbar container">
    <h1><img class="logo" src="assets/img/logo.png" alt="Logo da Gráfica dá Sorte" width="207" height="84"></h1>
    <button class="rp-btn-user" (click)="logout()" title="sair">
      <mat-icon matSuffix class="material-icons-outlined">logout</mat-icon>
    </button>
  </div>
</mat-toolbar>

<main class="container rp-container-default" [@flyInOut]="'in'">
  <h5>Olá, <span class="rp-user">{{this.User.login}}</span></h5>
  <p>Selecione a gráfica e categoria abaixo para acessar os arquivos.</p>

  <form class="rp-form-choice" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div>
      <mat-form-field appearance='outline'>
        <mat-label for="printShop">Gráfica</mat-label>
        <mat-select id="printShop" formControlName="printShop" [(ngModel)]="printShopSelected">
          <mat-option disabled value='-1'>Escolha à gráfica</mat-option>
          <mat-option *ngFor="let printer of listPrintShop" [value]="printer.id">
            {{ printer.nome }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="msgErro" *ngIf="!er_printShop.pristine && er_printShop.errors || submitForm && er_printShop.errors">
        <p *ngIf="er_printShop.errors.required">A gráfica deve ser informada.</p>
      </div>
    </div>

    <div>
      <mat-form-field appearance='outline'>
        <mat-label for="category">Categoria</mat-label>
        <mat-select id="category" formControlName="category">
          <mat-option disabled value='-1'>Escolha à categoria</mat-option>
          <mat-option *ngFor="let typeFile of listTypeFile" [value]="typeFile.id">
            {{ typeFile.nome }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="msgErro" *ngIf="!er_category.pristine && er_category.errors || submitForm && er_category.errors">
        <p *ngIf="er_category.errors.required">A categoria deve ser informada.</p>
      </div>
    </div>

    <button mat-raised-button type="submit" class="rp-btn-default" title="buscar" [disabled]="submitForm">Buscar</button>
  </form>

  <section *ngIf="listTypeFilePrint.length > 0" class="rp-card">
    <div class="rp-container-default rp-result">
      <div *ngFor="let typeFilePrint of listTypeFilePrint; let i = index" class="rp-cont-result">
        <span class="rp-title-result base-pe">{{ typeFilePrint.nome_empresa }}</span>
        <div class="rp-card rp-card-result">
          <mat-tree [dataSource]="dataSource[i]" [treeControl]="treeControl">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
              <button mat-icon-button disabled></button>
              <a (click)="download(node.id, this.form.value.printShop)" title="{{node.name}}">{{node.name}}</a>
            </mat-tree-node>

            <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
              <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name" title="{{node.name}}">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              {{node.name}}
            </mat-tree-node>
          </mat-tree>
        </div>
      </div>
    </div>
  </section>
</main>

