import {Injectable} from '@angular/core';

import Swal from 'sweetalert2';

import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class CommonService {

  constructor() {
  }

  public redirectLogin(): any {
    Swal.fire({
      title: 'Erro!',
      text: 'Faça login novamente.',
      icon: 'error',
      confirmButtonText: 'Ok',
      width: 400,
    });

    localStorage.clear();

    setTimeout(() => {
      window.location.href = environment.protocol + window.location.hostname + environment.port;
    }, 3000);
  }
}
