import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators, AbstractControl} from '@angular/forms';
import {Router} from '@angular/router';
import {trigger, state, style, animate, transition} from '@angular/animations';

import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('in', style({transform: 'translateX(0)'})),
      transition('void => *', [
        style({transform: 'translateX(-100%)'}),
        animate(500)
      ])
    ])
  ]
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  fieldTextType: boolean;
  passowrd = '';
  public submitForm = false;
  // Recebe os dados do usuário logado no Local Storage
  public User: any;


  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService) {
  }

  ngOnInit(): void {
    this.getUser();
    this.createForm();
  }

  /**
   * getUser(): valida os dados do usuário para liberar acesso a home ou retornar a tela de login
   */
  public getUser(): void {
    this.User = this.authService.getUserData();

    if (this.User.id !== 0 || this.User.token !== '') {
      this.router.navigate(['/home']);
    }
  }

  /**
   * toggleFieldTextType(): oculta ou mostra a senha
   */
  toggleFieldTextType(): void {
    this.fieldTextType = !this.fieldTextType;
  }

  /**
   * createForm(): cria o formulário do login
   */
  public createForm(): void {
    this.loginForm = this.fb.group({
      login: [null, Validators.compose([Validators.required])],
      pass: [null, Validators.compose([Validators.required])]
    });
  }

  /**
   * logar(): metódo chamado ao submeter o formulário de login
   */
  public logar(): void {
    this.submitForm = true;

    if (!this.loginForm.valid) {
      console.log('Formulário inválido!');
    } else {
      this.authService.login(this.loginForm.value.login, this.loginForm.value.pass).subscribe({
        next: (success) => {
          this.router.navigate(['/home']);
        },
        error: (error) => {
          console.log(error);
        }
      });
    }
  }

  get er_login(): AbstractControl {
    return this.loginForm.get('login');
  }

  get er_pass(): AbstractControl {
    return this.loginForm.get('pass');
  }
}
