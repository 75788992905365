// Definição do tipo de retorno da API.
export interface User {
  id: number;
  login: string;
  nome: string;
  token: string;
}

export function emptyUser(): User {
  return {
    id: 0,
    login: '',
    nome: '',
    token: ''
  };
}
