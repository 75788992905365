import {Component, Injectable, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormGroup, FormBuilder, FormControl, Validators, AbstractControl} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {trigger, state, style, animate, transition} from '@angular/animations';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';

import {Subscription} from 'rxjs';
import Swal from 'sweetalert2';

import {AuthService} from '../../services/auth.service';
import {FilesService} from '../../services/files.service';
import {FilePrintService} from '../../services/file-print.service';
import {GraphicService} from '../../services/graphic.service';
import {DownloadService} from '../../services/download.service';
import {CommonService} from '../../services/common-service.service';

import {environment} from '../../../environments/environment';


/**
 * Dados de alimentos com estrutura aninhada.
 * Cada nó tem um nome e uma lista opcional de filhos.
 */
interface ArchiveNode {
  name: string;
  id: number;
  children?: ArchiveNode[];
}

/** Nó plano com informações expansíveis e de nível */
interface StructureFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  id: number;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('in', style({transform: 'translateX(0)'})),
      transition('void => *', [
        style({transform: 'translateX(-100%)'}),
        animate(500)
      ])
    ])
  ]
})

export class HomeComponent implements OnInit, OnDestroy {
  private _transformer = (node: ArchiveNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      id: node.id
    };
  };

  treeControl = new FlatTreeControl<StructureFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  // Recebe os dados do usuário logado no Local Storage
  public User: any;

  // Recebe a lista de gráficas
  public listPrintShop = [];

  // Recebe a lista de tipos de arquivos
  public listTypeFile = [];

  // Recebe a gráfica selecionada para quando o login for diferente de administrador
  public printShopSelected;

  // Campos do formulário
  public form = new FormGroup({
    printShop: new FormControl(),
    category: new FormControl(),
  });

  // Recebe a lista de dados para impressão
  public dataSource = [];
  public listTypeFilePrint = [];

  // Recebe o id do documento
  public idDocument;
  public resultArrId: any;

  public submitForm = false;

  // Para acompanhar as mudanças em TypeFile
  private typeFileWatcher?: Subscription;

  // Para acompanhar as mudanças em getPrint
  private getPrintWatcher?: Subscription;


  constructor(
    private router: Router,
    private authService: AuthService,
    private fileService: FilesService,
    private filePrintService: FilePrintService,
    private graphicService: GraphicService,
    private downloadService: DownloadService,
    private commonService: CommonService,
    public http: HttpClient,
    private fb: FormBuilder) {

  }

  hasChild = (_: number, node: StructureFlatNode) => node.expandable;

  ngOnInit(): void {
    this.getUser();
    this.createForm();
    this.getPrintShop();
    this.getTypeFiles();
  }

  ngOnDestroy(): void {
    this.typeFileWatcher?.unsubscribe();
    this.getPrintWatcher?.unsubscribe();
  }

  /**
   * getUser(): valida os dados do usuário para liberar acesso a home ou retornar a tela de login
   */
  public getUser(): void {
    this.User = this.authService.getUserData();

    if (this.User === undefined || this.User.token === undefined || this.User.token === '') {
      this.router.navigate(['/']);
    }
  }

  /**
   * createForm(): cria o formulário de gráfica e categoria
   */
  public createForm(): void {
    this.form = this.fb.group({
      printShop: [null, Validators.compose([Validators.required])],
      category: [null, Validators.compose([Validators.required])]
    });
  }

  /**
   * getPrintShop(): carrega todas as gráficas
   */
  public getPrintShop(): void {
    this.graphicService.getPrintShopAll().subscribe({
      next: (success: any) => {
        success.forEach(printer => {
          this.listPrintShop.push(printer);
        });

        /**
         * Faz a verificação de quantas gráficas o array de graficas recebe, se for apenas 1, seta o nome da gráfica
         * assim que carrega a tela, se for mais de um, deixa para o usuário escolher a gráfica.
         */
        if (this.listPrintShop.length === 1) {
          this.printShopSelected = this.listPrintShop[0].id;
        }
      },
      error: (error) => {
        if (error.status === 401) {
          this.commonService.redirectLogin();
        }
      }
    });
  }

  /**
   * getTypeFiles(): carrega todas as categorias (tipos de arquivos)
   */
  public getTypeFiles(): void {
    this.fileService.getTypeFile().subscribe({
      next: (success: any) => {
        success.forEach(typeFile => {
          this.listTypeFile.push(typeFile);
        });
      },
      error: (error) => {
        if (error.status === 401) {
          this.commonService.redirectLogin();
        }
      }
    });
  }

  /**
   * Submit do formulário
   */
  public onSubmit(): void {
    this.submitForm = true;
    this.dataSource = [];
    this.listTypeFilePrint = [];

    if (!this.form.valid) {
      console.log('Preencha todos os campos');
    } else {
      this.filePrintService.getTypeFilePrint(this.form.value.printShop, this.form.value.category).subscribe({
        next: (success: any) => {
          if (success.empresas === null) {
            Swal.fire({
              title: 'Erro!',
              text: 'Nenhum arquivo encontrado.',
              icon: 'info',
              confirmButtonText: 'Ok',
              width: 400,
            });
          } else {
            success.empresas.forEach(typeFilePrint => {
              /**
               * cria uma nova árvore de dados e adiciona no array de árvores (dataSource)
               */
              const data = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
              data.data = typeFilePrint.impressao;
              this.dataSource.push(data);
              this.listTypeFilePrint.push(typeFilePrint);
            });
          }
          this.submitForm = false;
        },
        error: (error) => {
          this.submitForm = false;
        }
      });
    }
  }

  /**
   * download(): função para downalod dos arquivos
   */
  public download(idDoc, idPrintShop): void {
    window.location.assign(environment.host + ':' + environment.loginPort + '/download' + '?id=' + idDoc + '&id_grafica=' + idPrintShop + '&Access-Token=' + localStorage.rpToken);

    /**
     * Código comentado em caso de a requisição precisar ser feita pelo serviço
     */
    // this.downloadService.download(idDoc, idPrintShop).subscribe({
    //   next: (success: any) => {
    //     const doc = JSON.parse(success);
    //
    //     const file = new Blob([success.body], {
    //       type: 'blob'
    //     });
    //
    //     const blob = window.URL.createObjectURL(file);
    //
    //     const link = document.createElement('a');
    //     link.href = blob;
    //     link.download = 'archive.zip';
    //
    //     link.click();
    //
    //     window.URL.revokeObjectURL(blob);
    //     link.remove();
    //   },
    //   error: (error) => {
    //     console.log(error, 'error');
    //   }
    // });
  }


  /**
   * logout(): faz o logout do usuário
   */
  public logout(): void {
    this.User = this.authService.logOut();
  }

  get er_printShop(): AbstractControl {
    return this.form.get('printShop');
  }

  get er_category(): AbstractControl {
    return this.form.get('category');
  }
}
