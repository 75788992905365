import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable} from 'rxjs';

import {TypeFilePrint} from '../models/typeFilePrint';

import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class FilePrintService {
  httpOptions: any;

  constructor(
    private http: HttpClient) {
  }

  public getTypeFilePrint(idPrintShop: number, idTypeFile: number): Observable<TypeFilePrint> {
    this.httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Access-Token': localStorage.rpToken
        }
      )
    };

    return this.http.post(
      environment.host + ':' + environment.loginPort + '/infoFilesPrinter',
      {
        id: idPrintShop,
        typeFile: idTypeFile,
      },
      this.httpOptions) as unknown as Observable<TypeFilePrint>;
  }
}
