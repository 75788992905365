<main>
  <section class="rp-container-default container" [@flyInOut]="'in'">
    <h1><img src="assets/img/logo.png" alt="Logo da Gráfica dá Sorte" width="207" height="84"></h1>
    <!--    Formulário de login-->
    <div>
      <div class="rp-card backgroundOff">
        <!--*************-->
        <!--        TODO Ativar a progress bar quando o botão-->
        <!--          entrar for acionado e validado-->
        <!--*************-->
        <!--                        <div class="rp-progress-bar">-->
        <!--                          <mat-progress-bar mode="indeterminate"></mat-progress-bar>-->
        <!--                        </div>-->
        <div class="rp-cont-title">
          <h2>Olá!</h2>
          <p>Efetuar login</p>
        </div>
        <form class="rp-login" [formGroup]="loginForm" (ngSubmit)="logar()">
          <div>
            <mat-form-field appearance="outline">
              <mat-label for="login">Login</mat-label>
              <input matInput placeholder="Informe seu login" type="text" id="login" formControlName="login">
            </mat-form-field>
            <div class="msgErro" *ngIf="!er_login.pristine && er_login.errors || submitForm && er_login.errors">
              <p *ngIf="er_login.errors.required">O usuário é obrigatório.</p>
            </div>
          </div>

          <div>
            <mat-form-field appearance="outline">
              <mat-label for="pass">Senha</mat-label>
              <div class="input-pass">
                <input matInput placeholder="Informe sua senha" [type]="fieldTextType ? 'text' : 'password'"
                       [(ngModel)]="passowrd" id="pass" formControlName="pass">
                <span (click)="toggleFieldTextType()" class="show-pass">
                  <mat-icon matSuffix class="material-icons-outlined btn-visibility"
                            *ngIf="fieldTextType">visibility</mat-icon>
                  <mat-icon matSuffix class="material-icons-outlined btn-visibility"
                            *ngIf="!fieldTextType">visibility_off</mat-icon>
                </span>
              </div>
            </mat-form-field>
            <div class="msgErro" *ngIf="!er_pass.pristine && er_pass.errors || submitForm && er_pass.errors">
              <p *ngIf="er_pass.errors.required">A senha é obrigatória.</p>
            </div>
          </div>

          <button mat-raised-button class="rp-btn-default" type="submit" title="entrar">
            <span>Entrar</span>
            <mat-icon matSuffix class="material-icons-outlined">arrow_forward</mat-icon>
          </button>
        </form>
        <p class="rp-copy">&copy; Desenvolvido por <a href="http://www.redepos.com.br/" target="_blank" title="redepos">RedePOS</a></p>
      </div>
    </div>
  </section>
</main>
