import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable} from 'rxjs';

import {TypeFile} from '../models/typeFile';

import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class FilesService {
  httpOptions: any;

  constructor(
    private http: HttpClient) {
  }

  /**
   * getTypeFile: Responsável por retornar os tipos dos arquivos a serem baixados. Select Categoria.
   */
  public getTypeFile(): Observable<TypeFile> {
    this.httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Access-Token': localStorage.rpToken
        }
      )
    };

    return this.http.get(
      environment.host + ':' + environment.loginPort + '/typeFiles',
      this.httpOptions) as unknown as Observable<TypeFile>;
  }
}
