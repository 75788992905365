import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable} from 'rxjs';

import {Download} from '../models/download';

import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  httpOptions: any;

  constructor(
    private http: HttpClient) {
  }

  public download(idDoc, idPrintShop): Observable<Download> {
    this.httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Response-Type': 'arraybuffer'

          /**
           * Código comentado em caso de a requisição precisar ser feita pelo serviço
           */
          // 'Content-Type': 'application/x-www-form-urlencoded;'
          // 'Access-Token': localStorage.rpToken,
          // 'Response-Type': 'blob' as 'application/json'
        }
      )
    };

    // tslint:disable-next-line:max-line-length
    return this.http.get<Blob>(environment.host + ':' + environment.loginPort + '/download' + '?id=' + idDoc + '&id_grafica=' + idPrintShop + '&Access-Token=' + localStorage.rpToken,
      {observe: 'response', responseType: 'blob' as 'json'}) as unknown as Observable<Download>;
  }

  /**
   * Código comentado em caso de a requisição precisar ser feita pelo serviço
   */
  // public download(idDoc: number, idPrintShop: number): Observable<Download> {
  //   this.httpOptions = {
  //     headers: new HttpHeaders(
  //       {
  //         'Content-Type': 'application/json',
  //         'Access-Token': localStorage.rpToken,
  //         // 'Response-Type': 'blob' as 'application/json'
  //       }
  //     )
  //   };
  //
  //   return this.http.post(
  //     environment.host + ':' + environment.loginPort + '/download',
  //     {
  //       id: idDoc,
  //       id_grafica: idPrintShop,
  //     },
  //     this.httpOptions) as unknown as Observable<Download>;
  // }
}
