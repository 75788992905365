import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable} from 'rxjs';

import {PrintShop} from '../models/graphic';

import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class GraphicService {
  private httpOptions: any;

  constructor(
    private http: HttpClient) {
  }


  /**
   * getPrintShopAll(): responsável por retornar todas as gráficas cadastradas no sistema;
   */
  public getPrintShopAll(): Observable<PrintShop> {
    this.httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Access-Token': localStorage.rpToken
        }
      )
    };

    return this.http.get(
      environment.host + ':' + environment.loginPort + '/getPrinter',
      this.httpOptions) as unknown as Observable<PrintShop>;
  }
}
